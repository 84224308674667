/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import {t, RollingType, ComparisonType, legacyValidateInteger} from '@superset-ui/core';

import { ControlSubSectionHeader } from '../components/ControlSubSectionHeader';
import { ControlPanelSectionConfig } from '../types';
import { formatSelectOptions,tanslateFormatSelectOptions } from '../utils';
import {FORECAST_DEFAULT_DATA} from "./forecastInterval";

export const advancedAnalyticsControls: ControlPanelSectionConfig = {
  label: t('Advanced analytics'),
  tabOverride: 'data',
  description: t(
    'This section contains options ' +
      'that allow for advanced analytical post processing ' +
      'of query results',
  ),
  controlSetRows: [
    [<ControlSubSectionHeader>{t('Rolling window')}</ControlSubSectionHeader>],
    [
      {
        name: 'rolling_type',
        config: {
          type: 'SelectControl',
          label: t('Rolling function'),
          default: null,
          choices: [[null, t('None')]].concat(
            tanslateFormatSelectOptions(Object.values(RollingType)),
          ),
          description: t(
            'Defines a rolling window function to apply, works along ' +
              'with the [Periods] text box',
          ),
        },
      },
    ],
    [
      {
        name: 'rolling_periods',
        config: {
          type: 'TextControl',
          label: t('Periods'),
          isInt: true,
          description: t(
            'Defines the size of the rolling window function, ' +
              'relative to the time granularity selected',
          ),
          visibility: ({ controls }, { name }) => {
            // `rolling_type_b` refer to rolling_type in mixed timeseries Query B
            const rollingTypeControlName = name.endsWith('_b')
              ? 'rolling_type_b'
              : 'rolling_type';
            return (
              Boolean(controls[rollingTypeControlName]?.value) &&
              controls[rollingTypeControlName]?.value !== RollingType.Cumsum
            );
          },
        },
      },
    ],
    [
      {
        name: 'min_periods',
        config: {
          type: 'TextControl',
          label: t('Min periods'),
          isInt: true,
          description: t(
            'The minimum number of rolling periods required to show ' +
              'a value. For instance if you do a cumulative sum on 7 days ' +
              'you may want your "Min Period" to be 7, so that all data points ' +
              'shown are the total of 7 periods. This will hide the "ramp up" ' +
              'taking place over the first 7 periods',
          ),
          visibility: ({ controls }, { name }) => {
            // `rolling_type_b` refer to rolling_type in mixed timeseries Query B
            const rollingTypeControlName = name.endsWith('_b')
              ? 'rolling_type_b'
              : 'rolling_type';
            return (
              Boolean(controls[rollingTypeControlName]?.value) &&
              controls[rollingTypeControlName]?.value !== RollingType.Cumsum
            );
          },
        },
      },
    ],
    [<ControlSubSectionHeader>{t('Time comparison')}</ControlSubSectionHeader>],
    [
      {
        name: 'time_compare',
        config: {
          type: 'SelectControl',
          multi: true,
          freeForm: true,
          label: t('Time shift'),
          choices: [
            ['同比', t('同比')],
            ['月环比', t('月环比')],
            ['周环比', t('周环比')],
            ['天环比', t('天环比')],
            ['季环比', t('季环比')],
            ['1 day ago', t('1 day ago')],
            ['1 week ago', t('1 week ago')],
            ['28 days ago', t('28 days ago')],
            ['30 days ago', t('30 days ago')],
            ['52 weeks ago', t('52 weeks ago')],
            ['1 year ago', t('1 year ago')],
            ['104 weeks ago', t('104 weeks ago')],
            ['2 years ago', t('2 years ago')],
            ['156 weeks ago', t('156 weeks ago')],
            ['3 years ago', t('3 years ago')],
          ],
          description: t(
            'Overlay one or more timeseries from a ' +
              'relative time period. Expects relative time deltas ' +
              'in natural language (example:  24 hours, 7 days, ' +
              '52 weeks, 365 days). Free text is supported.',
          ),
          tokenSeparators: ['\r\n', '\n', '\t', ';'],
        },
      },
    ],
    [
      {
        name: 'comparison_type',
        config: {
          type: 'SelectControl',
          label: t('Calculation type'),
          default: 'values',
          choices: [
            [ComparisonType.Values, t('Actual values')],
            [ComparisonType.Difference, t('Difference')],
            [ComparisonType.Percentage, t('Percentage change')],
            [ComparisonType.Ratio, t('Ratio')],
          ],
          description: t(
            'How to display time shifts: as individual lines; as the ' +
              'difference between the main time series and each time shift; ' +
              'as the percentage change; or as the ratio between series and time shifts.',
          ),
        },
      },
    ],
    [
      {
        name: 'time_comparison_rolling_type',
        config: {
          type: 'SelectControl',
          label: t('时间偏移滚动窗口'),
          default: null,
          choices: [
            [null, t('不做滚动窗口计算')],
            ["continuous", t('连续')],
            ["same_hour_of_day", t('每天同小时时间段')],
            ["same_day_of_week", t('每周同一天')],
            ["same_day_of_month", t('每月同一天')],
            ["same_day_of_year", t('每年同一天')],
            ["same_minute", t('每小时同分钟')],
          ],
          description: t(
            '开启时间偏移后，可以打开此项进行时间滚动计算。' +
              '普通的时间偏移只会点到点做对比，而加上滚动计算后，会对两个时间点之间的所有数据一起做滚动窗口计算。可以指定具体滚动窗口函数。' +
              '例：当设置时间偏移为周环比，并设置滚动窗口计算为same_hour_of_day时，会对过去一周每天同小时的数据一起计算（比如平均值）。' +
              '默认情况下，窗口不包含当前行的数据。比如，1月8号这一天的对比数据会是1月1日~1月7日的数据。',
          ),
        },
      },
    ],
    [
      {
        name: 'time_comparison_rolling_func',
        config: {
          type: 'SelectControl',
          label: t('时间偏移滚动窗口函数'),
          default: 'avg',
          choices: [
            ["avg", t('平均值')],
          ],
          description: t(
            '时间偏移滚动窗口函数',
          ),
        },
      },
    ],
    [
      {
        name: 'offset_metrics_order',
        config: {
          type: 'SelectControl',
          label: t('偏移指标排序方式'),
          default: null,
          choices: [
            [null, t('不排序')],
            ["asc", t('升序')],
            ["desc", t('降序')],
          ],
          description: t(
            '是否按偏移指标排序。如果有多个偏移指标，会按指标顺序加入排序。注：会覆盖原有排序方式',
          ),
        },
      },
    ],
    [
      {
        name: 'filter_nan_offset_metrics',
        config: {
          type: 'SelectControl',
          label: t('偏移指标过滤方式'),
          default: null,
          choices: [
            [null, t('不过滤')],
            ["all", t('全部为N/A时过滤')],
            ["any", t('任一为N/A时过滤')],
          ],
          description: t(
            '在时间比较结果集上偏移指标过滤方式。all:过滤所有偏移指标都为N/A的行，any:过滤任一偏移指标为N/A的行。',
          ),
        },
      },
    ],
    [
      {
        name: 'reserve_actual_values',
        config: {
          type: 'CheckboxControl',
          label: t('保留实际值'),
          renderTrigger: false,
          default: false,
          description: t(
            '是否保留实际值（会多一列）',
          ),
        },
      },
    ],
    [
      {
        name: 'offset_metrics_limit',
        config: {
          type: 'TextControl',
          label: t('行限制（时间比较）'),
          validators: [legacyValidateInteger],
          default: null,
          description: t(
            '在时间比较结果集上的limit。不影响原始查询',
          ),
        },
      },
    ],
    [<ControlSubSectionHeader>{t('Resample')}</ControlSubSectionHeader>],
    [
      {
        name: 'resample_rule',
        config: {
          type: 'SelectControl',
          freeForm: true,
          label: t('Rule'),
          default: null,
          choices: [
            ['1T', t('1 minutely frequency')],
            ['1H', t('1 hourly frequency')],
            ['1D', t('1 calendar day frequency')],
            ['7D', t('7 calendar day frequency')],
            ['1MS', t('1 month start frequency')],
            ['1M', t('1 month end frequency')],
            ['1AS', t('1 year start frequency')],
            ['1A', t('1 year end frequency')],
          ],
          description: t('Pandas resample rule'),
        },
      },
    ],
    [
      {
        name: 'resample_method',
        config: {
          type: 'SelectControl',
          label: t('Fill method'),
          default: null,
          choices: [
            ['asfreq', t('Null imputation')],
            ['zerofill', t('Zero imputation')],
            ['linear', t('Linear interpolation')],
            ['ffill', t('Forward values')],
            ['bfill', t('Backward values')],
            ['median', t('Median values')],
            ['mean', t('Mean values')],
            ['sum', t('Sum values')],
          ],
          description: t('Pandas resample method'),
        },
      },
    ],
  ],
};
