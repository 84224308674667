/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import {
  legacyValidateInteger,
  legacyValidateNumber,
  t,
} from '@superset-ui/core';
import { ControlPanelSectionConfig } from '../types';

export const FORECAST_DEFAULT_DATA = {
  forecastEnabled: false,
  forecastInterval: 0.8,
  forecastPeriods: 10,
  cap: 1.2,
  floor: 0.8,
  changepointPriorScale: 0.05,
  nChangepoints: 25,
  forecastSeasonalityDaily: null,
  forecastSeasonalityWeekly: null,
  forecastSeasonalityYearly: null,
  growth: 'logistic',
  seasonalityMode: 'multiplicative',
};

export const forecastIntervalControls: ControlPanelSectionConfig = {
  label: t('Predictive Analytics'),
  expanded: false,
  controlSetRows: [
    [
      {
        name: 'forecastEnabled',
        config: {
          type: 'CheckboxControl',
          label: t('Enable forecast'),
          renderTrigger: false,
          default: FORECAST_DEFAULT_DATA.forecastEnabled,
          description: t('Enable forecasting'),
        },
      },
    ],
    [
      {
        name: 'forecastPeriods',
        config: {
          type: 'TextControl',
          label: t('Forecast periods'),
          validators: [legacyValidateInteger],
          default: FORECAST_DEFAULT_DATA.forecastPeriods,
          description: t(
            'How many periods into the future do we want to predict',
          ),
        },
      },
    ],
    [
      {
        name: 'forecastInterval',
        config: {
          type: 'TextControl',
          label: t('Confidence interval'),
          validators: [legacyValidateNumber],
          default: FORECAST_DEFAULT_DATA.forecastInterval,
          description: t(
            'Width of the confidence interval. Should be between 0 and 1',
          ),
        },
      },
    ],
    [
      {
        name: 'cap',
        config: {
          type: 'TextControl',
          label: t('预测上界比例'),
          validators: [legacyValidateNumber],
          default: FORECAST_DEFAULT_DATA.cap,
          description: t(
            '设置一个预测上界比例。预测时用该值乘以实际值最大值作为预测上界',
          ),
        },
      },
    ],
    [
      {
        name: 'floor',
        config: {
          type: 'TextControl',
          label: t('预测下界比例'),
          validators: [legacyValidateNumber],
          default: FORECAST_DEFAULT_DATA.floor,
          description: t(
            '设置一个预测下界比例。预测时用该值乘以实际值最小值作为预测下界',
          ),
        },
      },
    ],
    [
      {
        name: 'changepointPriorScale',
        config: {
          type: 'TextControl',
          label: t('突变点先验比例'),
          validators: [legacyValidateNumber],
          default: FORECAST_DEFAULT_DATA.changepointPriorScale,
          description: t(
            '突变点先验比例，用于调整模型对趋势变化的灵敏度。',
          ),
        },
      },
    ],
    [
      {
        name: 'nChangepoints',
        config: {
          type: 'TextControl',
          label: t('突变点个数'),
          validators: [legacyValidateNumber],
          default: FORECAST_DEFAULT_DATA.nChangepoints,
          description: t(
            '突变点个数',
          ),
        },
      },
    ],

    [
      {
        name: 'growth',
        config: {
          type: 'SelectControl',
          freeForm: false,
          label: t('增长模型'),
          choices: [
            ['logistic', t('逻辑增长（logistic）')],
            ['linear', t('线性增长（linear）')],
          ],
          default: FORECAST_DEFAULT_DATA.growth,
          description: t(
            'prophet 增长模型',
          ),
        },
      },
    ],
    [
      {
        name: 'forecastSeasonalityYearly',
        config: {
          type: 'SelectControl',
          freeForm: true,
          label: t('Yearly seasonality'),
          choices: [
            [null, t('default')],
            [true, t('Yes')],
            [false, t('No')],
          ],
          default: FORECAST_DEFAULT_DATA.forecastSeasonalityYearly,
          description: t(
            'Should yearly seasonality be applied. An integer value will specify Fourier order of seasonality.',
          ),
        },
      },
    ],
    [
      {
        name: 'forecastSeasonalityWeekly',
        config: {
          type: 'SelectControl',
          freeForm: true,
          label: t('Weekly seasonality'),
          choices: [
            [null, t('default')],
            [true, t('Yes')],
            [false, t('No')],
          ],
          default: FORECAST_DEFAULT_DATA.forecastSeasonalityWeekly,
          description: t(
            'Should weekly seasonality be applied. An integer value will specify Fourier order of seasonality.',
          ),
        },
      },
    ],
    [
      {
        name: 'forecastSeasonalityDaily',
        config: {
          type: 'SelectControl',
          freeForm: true,
          label: t('Daily seasonality'),
          choices: [
            [null, t('default')],
            [true, t('Yes')],
            [false, t('No')],
          ],
          default: FORECAST_DEFAULT_DATA.forecastSeasonalityDaily,
          description: t(
            'Should daily seasonality be applied. An integer value will specify Fourier order of seasonality.',
          ),
        },
      },
    ],
    [
      {
        name: 'seasonalityMode',
        config: {
          type: 'SelectControl',
          freeForm: false,
          label: t('季节性模式'),
          choices: [
            ['multiplicative', t('乘法（multiplicative）')],
            ['additive', t('加法（additive）')],
          ],
          default: FORECAST_DEFAULT_DATA.seasonalityMode,
          description: t(
            'prophet 季节性模式',
          ),
        },
      },
    ],
  ],
};
